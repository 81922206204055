import React from "react";
import { Paper, CircularProgress, Box, Typography, OutlinedInput, Button, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import useStyles from "./styles";
import logo from "./logo.png";

export interface AuthPageProps {
    isLoading: boolean;
    heading: string;
    message: string;
    handleButtonClickCb?: () => void;
    error?: string;
}

const AuthPage = ({ isLoading, heading, message = "", handleButtonClickCb, error }: AuthPageProps) => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear();

    return (
        <Box display="flex" alignItems="center" justifyContent="center" width={1} height="100vh" className={classes.background}>
            <Box display="flex" flexDirection="column">
                <Paper>
                    <Box display="flex" flexDirection="column" minWidth="350px" px={6} pt={8} pb={10}>
                        <Box alignSelf="center">
                            <img src={logo} alt="logo" />
                        </Box>
                        <Box alignSelf="center" fontWeight={600} mt={1}>
                            <Typography variant="h2">{heading}</Typography>
                        </Box>
                        <Box alignSelf="center" my={3}>
                            <Typography variant="h3">{message}</Typography>
                        </Box>
                        <Box alignSelf="center">
                            {handleButtonClickCb ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.button}
                                    onClick={handleButtonClickCb}
                                    disabled={isLoading}
                                    disableElevation>
                                    {isLoading ? <CircularProgress size={24} /> : "Sign In"}
                                </Button>
                            ) : (
                                <CircularProgress size={64} />
                            )}
                        </Box>
                        {error && (
                            <Box mt={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                    </Box>
                </Paper>
                <Box display="flex" justifyContent="center" py={3}>
                    <Typography variant="caption">
                        {`© Copyright ${currentYear} Proficient Learning, LLC  •  `}
                        <Link href="mailto:theteam@myomnicoach.com?subject=Omni coach Support Request" target="_blank">
                            Contact Support
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default AuthPage;
